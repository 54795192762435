<template>
    <div class='cards all_white'>
        <p class="cards_title">我的银行卡</p>
        <ul v-if="hasData" class="cards_items">
            <li v-for="(card, index) in cards" :key="index" :style="bgStyle(card.level)">
                <p>
                    <span>{{card.name}}</span>
                    <span @click="unbind(card, index)">解绑</span>
                </p>
                <h3>{{hiddenNumber(card.number)}}</h3>
            </li>
        </ul>
        <div v-else class="cards_tip">
            您还<span class="red">未绑定</span>银行卡，点击下方按钮添加
        </div>
        <button v-show="cardLen < 4" class="cards_add" @click="addBank">添加银行卡</button>
    </div>
</template>
<script>
import { computed, reactive, toRefs } from 'vue'
import { unbindCard, userBankCards } from '../../utils/api'
import { jumpTo, toast, getUserInfo, sleep } from '../../utils/util'
export default {
    name: '',
    setup() {
        const state= reactive({
            cards: [],
            color: [
                {key: 'HSB', color: '#f24444 #de0a14'},
                {key: 'HXB', color: '#f24444 #de0a14'},
                {key: 'ICBC', color: '#f24444 #de0a14'},
                {key: 'GDB', color: '#f24444 #de0a14'},
                {key: 'NGCB', color: '#f24444 #de0a14'},
                {key: 'GZCB', color: '#f24444 #de0a14'},
                {key: 'CZB', color: '#f24444 #de0a14'},
                {key: 'CMB', color: '#f24444 #de0a14'},
                {key: 'CCITICB', color: '#f24444 #de0a14'},
                {key: 'CEB', color: '#953db3 #6a1e84'},
                {key: 'BEA', color: '#f24444 #de0a14'},
                {key: 'BOB', color: '#f24444 #de0a14'},
                {key: 'BOC', color: '#f24444 #de0a14'},
                {key: 'BCM', color: '#3270bd #105097'},
                {key: 'SDB', color: '#3270bd #105097'},
                {key: 'SPDB', color: '#3270bd #105097'},
                {key: 'SPCB', color: '#3270bd #105097'},
                {key: 'CMBC', color: '#3270bd #105097'},
                {key: 'CIB', color: '#3270bd #105097'},
                {key: 'CBB', color: '#3270bd #105097'},
                {key: 'CCB', color: '#3270bd #105097'},
                {key: 'ABC', color: '#0fbb9e #00a085'},
                {key: 'CCB', color: '#3270bd #105097'},
                {key: 'BOB', color: '#3270bd #105097'},
                {key: 'PAB', color: '#fb7543 #f05c25'},
                {key: 'PSBC', color: '#28a961 #12793f'},
                {key: 'PAB', color: '#fb7543 #f05c25'},
                {key: 'GZRCC', color: '#f0bf3a #eaa500'},
                {key: 'HZB', color: '#1eb4e9 #1172c4'},
                {key: 'NBCB', color: '#fb7543 #f05c25'},
            ]
        })
        const cardLen = computed(() => {
            return state.cards && state.cards.length
        })
        const hasData = computed(() => {
            return state.cards && state.cards.length > 0
        })
        function initColor() {
            let color = {}
            state.color.forEach(ele => {
                color[ele.key] = ele.color.split(' ')
            })
            return color
        }
        function hiddenNumber(num) {
            return num.replace(/(.{4}).*(.{4})/, '$1**********$2')
        }
        const bgColor = initColor()
        async function loadData() {
            try {
                const result = await userBankCards()
                if (result.code == 0) {
                    console.log(result.data)
                    state.cards = result.data.bindAccouts
                }
            } catch(e) {
                console.error(e)
            }
        }
        async function unbind(item, index) {
            try {
                const result = await unbindCard(item.id)
                if (result.code == 0){
                    state.cards.splice(index, 1)
                    toast('解绑成功')
                }
            } catch(e) {
                console.error(e)
            }
        }
        function bgStyle(key) {
            const color = bgColor[key]
            return {
                background: `linear-gradient(to right, ${color[0]}, ${color[1]})`
            }
        }
        async function addBank() {
            try {
                const info = await getUserInfo()
                if (info.realName) {
                    jumpTo('/addBankCard')
                } else {
                    toast('需先完善个人信息才能添加银行卡')
                    await sleep(2000)
                    jumpTo(`/fillInfo?name=${info.realName}&IdCard=${info.cardNumber}`)
                }
            } catch(e) {
                console.error(e)
            }
            
        }
        loadData()
        return {
            ...toRefs(state),
            unbind,
            bgStyle,
            addBank,
            cardLen,
            hasData,
            hiddenNumber
        }
    },
}
</script>
<style lang='scss' scoped>
    .cards {
        padding: $margin-big;
        &_title {
            font-weight: 700;
            padding: 10px 0;
            font-size: $font-big;
        }
        &_tip {
            color: $gray-light;
            text-align: center;
            padding: 40px 0 30px 0;
        }
        &_items {
            li {
                padding: 15px $margin-big;
                background: $theme-dark;
                color: #fff;
                margin-bottom: $margin-big;
                border-radius: $box-radius-small;
                p {
                    display: flex;
                    justify-content: space-between;
                    opacity: 0.85;
                    // background: linear-gradient(to right, );
                }
                h3 {
                    padding-top: 8px;
                    font-size: 21px;
                    font-weight: 400;
                }
            }
        }
        &_add {
            background: $theme-dark;
            color: #fff;
            font-size: $font-big;
            text-align: center;
            width: 100%;
            padding: 10px 0;
            display: block;
            border-radius: $box-radius-small;
            margin-top: 20px;
        }
    }
</style>